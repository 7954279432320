import React from "react";
import { Link } from "react-router-dom";

import background from "../../assets/images/hero/hero-connection-bg.jpg";

function Hero() {
  return (

    <section
    className="hero-section overlay bgs-cover pt-150 pb-130"
    style={{ backgroundImage: `url(${background})` }}
  >
    <div className="container">
      <div className="row justify-content-center">
        <div className="">
          <div className="hero-content text-center">
            {/* <span className="sub-title d-block wow fadeInUp delay-0-2s">
              Multi GNSS
            </span> */}
            <h1 className="wow fadeInUp delay-0-4s mt-20 hero-text">
              NavIC based <br />
              Position, Navigation and Timing systems
            </h1>
            <div className="hero-btn mt-35 wow fadeInUp delay-0-6s">
              <Link to="/contact" className="theme-btn">
                meet with us
              </Link>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Hero;
